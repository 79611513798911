export enum Tag {
  Accounts = 'Accounts',
  AccountGroups = 'AccountGroups',
  AccountGroupAccounts = 'AccountGroupAccounts',
  Users = 'Users',
  EDDs = 'EDDs',
  SettingDefinitions = 'SettingDefinitions',
  SKUs = 'SKUs',
  Fulfillment = 'Fulfillment',
  AccountImportJobs = 'AccountImportJobs'
}

export enum TagLabel {
  List = 'LIST',
  Current = 'CURRENT'
}

export type ValidFilterValue =
  | undefined
  | Array<boolean | number | string>
  | boolean
  | number
  | string;

export type Filters = Record<string, undefined | ValidFilterValue>;

export interface SearchParams {
  pageNumber: number;
  pageSize: number;
  sort?: Sort | string;
  filters?: Filters;
  include?: string;
  searchTerm?: string;
  excludeFromUser?: boolean;
  accountType?: number;
  accountId?: number;
  excludeFromAccount?: boolean;
  searchQueryType?: string;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface Sort {
  direction: SortDirection;
  key: string;
}
